import { has, isArray, isEmpty, isObject, isString, startCase } from 'lodash';
import logger from './logger';

export const getUserFullName = (userInfo) => {
  let fullName = 'someone';
  if (!isEmpty(userInfo)) {
    fullName = `${userInfo.givenName} ${userInfo.familyName}`;
  }
  return startCase(fullName);
};

export const getProviderFullName = (providerInfo) => {};

export const extractError = (error: any) => {
  if (has(error, 'response')) {
    let status = 0,
      data = {},
      statusText = '',
      headers = {},
      config = {},
      cooeeError = null,
      request = {};
    if (has(error, 'response.status')) status = error.response.status;
    if (has(error, 'response.data')) data = error.response.data;
    if (has(error, 'response.statusText')) {
      statusText = error.response.statusText;
    }
    if (has(error, 'response.headers')) headers = error.response.headers;
    if (has(error, 'response.config')) config = error.response.config;
    if (has(error, 'response.request')) request = error.response.request;

    if (
      has(error, 'response.data.error') &&
      isObject(error.response.data.error)
    ) {
      cooeeError = error.response.data?.error;
    } else if (status !== 200 && isString(data)) {
      cooeeError = {};
      cooeeError['message'] = data;
    } else {
      cooeeError = {};
      cooeeError['message'] = 'Internal error';
    }
    return { status, data, statusText, headers, config, request, cooeeError };
  } else return 'Internal error';
};
